<template>

<title>Скарлетт и Монарх</title> 
<router-view/>
</template>

<script>
import "./assets/scss/index.scss";

export default {};
</script>